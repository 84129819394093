import { combineReducers } from "redux";
import {
  modalReducer,
  filterReducer,
} from "./mode";

export default combineReducers({
  modal: modalReducer,
  categoryFilter: filterReducer,
});
